import * as React from 'react'

import { List, ListItem, ListItemText } from '@mui/material'

import { rangeLabels } from './range_buttons'
import { AppContainer, Currency, PercentVariation } from '../components'

import type { GraphData } from '../services'

export type PriceStatisticsProps = {
  currency?: string
  range: string
  graphData: GraphData
}

export const PriceStatistics = ({
  currency = 'USD',
  range,
  graphData,
}: PriceStatisticsProps) => (
  <AppContainer
    md={6}
    sx={{ height: '100%' }}
  >
    <List
      aria-label='Estadísticas de Precio'
      sx={{ p: 1 }}
    >
      <ListItem divider>
        <ListItemText
          primaryTypographyProps={{
            variant: 'h5',
          }}
          primary='Estadísticas de Precio'
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Precio actual'
          secondary={`Variación ${rangeLabels[range].long.toLowerCase()}`}
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(
            <Currency
              currency={currency}
              value={graphData.lastPrice}
            />
          )}
          secondary={(
            <PercentVariation
              value={graphData.variation}
            />
          )}
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary='Precio máximo'
          secondary={rangeLabels[range].long}
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(
            <Currency
              currency={currency}
              value={graphData.ath}
            />
          )}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary='Precio mínimo'
          secondary={rangeLabels[range].long}
        />
        <ListItemText
          primaryTypographyProps={{
            textAlign: 'right',
          }}
          primary={(
            <Currency
              currency={currency}
              value={graphData.atl}
            />
          )}
        />
      </ListItem>
    </List>
  </AppContainer>
)
